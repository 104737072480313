import Vue from "vue";
import Vuex from "vuex";
import common from "./modules/common";
import login from "./modules/login";
import excerpt from "./modules/excerpt";
import slideShow from "./modules/slideShow";
import file from "./modules/file";
import fileDownload from "./modules/fileDownload";
import other from "./modules/other";
import user from "./modules/user";
import video from "./modules/video";
import course from "./modules/course";
import marketing from "./modules/marketing";
import store from "./modules/store";
import sales from "./modules/sales";
import teacherCourse from "./modules/teacherCourse";
import specialColumn from "./modules/specialColumn";
import classManage from "./modules/classManage";
import message from "./modules/message";
import punch from "./modules/punch";
import questionBank from "./modules/questionBank";
import annotation from "./modules/annotation";
import audio from "./modules/audio";
import adv from "./modules/adv";
import images from "./modules/images";
import knowledge from "./modules/knowledge";
import teacherRecord from "./modules/teacherRecord";
import aimActivity from "./modules/aimActivity";
import hearBook from "./modules/hearBook";
import vipSet from "./modules/vipSet";
import game from "./modules/game";
import poster from "./modules/poster";
import quiz from "./modules/quiz";
import socket from "./modules/socket";
import createPersistedState from "vuex-persistedstate";
import classChat from "./modules/classChat";
import extension from "./modules/extension";
import promote from "./modules/promote";
import popularize from "./modules/popularize";
import promotionRecord from "./modules/promotionRecord";
import live from "./modules/live";
import flow from "./modules/flow";
import errorList from "./modules/errorList";
import startDiagram from "./modules/startDiagram";
import listAuthor from "./modules/listAuthor";
import listType from "./modules/listType";
import listDynasty from "./modules/listDynasty";
import listPoem from "./modules/listPoem";
import customerCard from "./modules/customerCard";
import ruleDistribution from "./modules/ruleDistribution";
import teacherGrouping from "./modules/teacherGrouping";
import evaluation from "./modules/evaluation";
import liveOrder from "./modules/liveOrder";
import advertising from "./modules/advertising";
import mailbox from "./modules/mailbox";
import dataExtract from "./modules/dataExtract";
import collaborate from "./modules/collaborate";
import gift from "./modules/gift";
import rule from "./modules/rule";
import avatarFrame from "./modules/avatarFrame";

Vue.use(Vuex);
//modules
export default new Vuex.Store({
  modules: {
    common,
    login,
    slideShow,
    excerpt,
    file,
    fileDownload,
    other,
    user,
    video,
    marketing,
    course,
    teacherCourse,
    teacherRecord,
    sales,
    store,
    specialColumn,
    classManage,
    message,
    punch,
    questionBank,
    annotation,
    audio,
    images,
    adv,
    knowledge,
    aimActivity,
    hearBook,
    vipSet,
    game,
    extension,
    promote,
    popularize,
    poster,
    quiz,
    socket,
    classChat,
    promotionRecord,
    live,
    flow,
    errorList,
    startDiagram,
    listAuthor,
    listType,
    listDynasty,
    listPoem,
    customerCard,
    ruleDistribution,
    teacherGrouping,
    evaluation,
    liveOrder,
    advertising,
    mailbox,
    dataExtract,
    collaborate,
    gift,
    rule,
    avatarFrame,
  },
  strict: false,
  plugins: [
    createPersistedState({
      key: "erabbit-client-pc-store",
      paths: ["user"],
    }),
  ],
});
