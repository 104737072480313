import { config } from '../../js/config'
import { Api } from '../../service/Api'

const state = {}

const getters = {}

const actions = {
	getChannelList({ commit }, data) {
		return new Api().getList(config.getChannelList, data)
	},

	getChannelDetail({ commit }, data) {
		return new Api().getList(config.getChannelDetail, data)
	},
	userMessageStationList({ commit }, data) {
		return new Api().getList(config.userMessageStationList, data)
	},
	userMessageStationDetail({ commit }, data) {
		return new Api().del(config.userMessageStationDetail, data)
	},
	unreadLimit({ commit }, data) {
		return new Api().getList(config.unreadLimit, data)
	},
	listClassUnSignedUser({ commit }, data) {
		return new Api().getList(config.listClassUnSignedUser,data)
	}
}

const mutations = {}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
