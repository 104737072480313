import { Api } from "@/service/Api";
import { config } from "@/js/config";

const state = () => ({});

const getters = {};

const actions = {
  toastAdList({ commit }, data) {
    return new Api().getList(config.toastAdList, data);
  },
  toastAdAdd({ commit }, data) {
    return new Api().getList(config.toastAdAdd, data);
  },
  toastAdGet({ commit }, data) {
    return new Api().getList(config.toastAdGet, data);
  },
  toastAdDel({ commit }, data) {
    return new Api().getList(config.toastAdDel, data);
  },
  toastAdSetStatus({ commit }, data) {
    return new Api().getList(config.toastAdSetStatus, data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
