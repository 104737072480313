import { Api } from "../../service/Api";

const state = () => ({});

const getters = {};

const actions = {
  listPoem({ commit }, data) {
    return new Api().getList(config.listPoem, data);
  },
  delPoem({ commit }, data) {
    return new Api().del(config.delPoem, data);
  },
  addPoem({ commit }, data) {
    return new Api().getList(config.addPoem, data);
  },
  getPoem({ commit }, data) {
    return new Api().del(config.getPoem, data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
