import { config } from '../../js/config'
import { Api } from '../../service/Api'
import  axios  from 'axios'
import tools from '../../utils/tools'

const state = {}

const getters = {}

const actions = {
	addAdv({ commit }, data ){
		return new Api().add(config.addAdv, data)
	},
    getAdvList({ commit }, data){
        return new Api().getList(config.getAdv,data)
    },
    getAdvDetail({ commit }, data){
        return new Api().detail(config.getAdvDetail,data)
    },
    delAdv({ commit }, id){
        return new Api().del(config.delAdv,id)
    },
	updateAdvStatus({ commit }, data){
		return new Api().add(config.updateAdvStatus,data)
	}
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
