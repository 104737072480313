import { config } from '../js/config'
import request from '../request/request'
export class Api {
	login(phone, password, type = 2) {
		return request.post(config.login, {
			phone: String(phone),
			password: String(password),
			type: Number(type)
		})
	}
	wxLogin(code) {
		return request.post(config.login, { code: String(code), type: 3 })
	}
	getOssInfo(dir) {
		return request.post(config.getOssInfo, { dir: String(dir) })
	}
	getVideoUploadAccess(data = {}) {
		return request.post(config.getVideoUploadAccess, data)
	}
	makeQrCode(data) {
		return request.post(config.makeQrCode, data)
	}
	getBaseData(data = {}) {
		return request.post(config.getBaseData, data)
	}
	setStatus(model, id, status) {
		return request.post(config.setStatus, {
			model: model,
			id: Number(id),
			status: Number(status)
		})
	}
	getSlideShowList(data = {}) {
		return request.post(config.getSlideShowList, data)
	}
	delSlideShow(id) {
		return request.post(config.delSlideShow, { id: Number(id) })
	}
	addSlideShow(pic, type, status, resource) {
		return request.post(config.addSlideShow, {
			pic: pic,
			type: Number(type),
			status: Number(status),
			resource: resource
		})
	}

	getUserInfo() {
		return request.post(config.getUserInfo)
	}
	delete(data = {}) {
		return request.post(config.delete, data)
	}

	//获取列表
	getList(url, data) {
		return request.post(url, data)
	}
	//新增
	add(url, data = {}) {
		return request.post(url, data)
	}
	//修改
	edit(url, data = {}) {
		return request.post(url, data)
	}
	//删除
	del(url, id) {
		return request.post(url, { id: id })
	}
	commonDelete(model, id) {
		return request.post(config.delete, { model: model, id: Number(id) })
	}

	push(id, type) {
		return request.post(config.push, { id: id, type: type })
	}

	service(url, data = {}) {
		return request.service(url, data)
	}

	detail(url, data = {}){
		return request.post(url,data);
	}
	post(url, data = {}){
		return request.post(url,data)
	}
}
