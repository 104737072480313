import axios from 'axios'
import { config } from '../js/config'
const https = require('https')

const post = (url, data = {}) => {
	url = config.baseUrl + url
	for (let key in data) {
		if (
			typeof data[key] != 'object' &&
			(data[key] === '' || data[key] === undefined)
		) {
			delete data[key]
		}
	}

	let agent = new https.Agent({ rejectUnauthorized: false })
	return axios.post(url, data, { httpsAgent: agent })
}

const service = (url, data = {}) => {
	url = config.serviceUrl + url

	for (let key in data) {
		if (
			typeof data[key] != 'object' &&
			(data[key] === '' || data[key] === undefined)
		) {
			delete data[key]
		}
	}

	let agent = new https.Agent({ rejectUnauthorized: false })
	return axios.post(url, data, { httpsAgent: agent })
}

export default {
	post: post,
	service: service
}
