import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    listAskQuestion({commit},data){
        return (new Api()).getList(config.listAskQuestion,data)
    },
    showAnswer({commit},data){
        return (new Api()).getList(config.showAnswer,data)
    },
    selectCheckCourse({commit}){
        return (new Api()).getList(config.selectCheckCourse)
    },
    exportUsers({commit},data){
        return (new Api()).getList(config.exportUsers,data)
    },
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
