import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    listCustomerDistributionRule({commit},data){
        return (new Api()).getList(config.listCustomerDistributionRule,data)
    },
    selectCounselor({commit},data){
        return (new Api()).getList(config.selectCounselor,data)
    },
    addCustomerDistributionRule({commit},data){
        return (new Api()).getList(config.addCustomerDistributionRule,data)
    },
    getCustomerDistributionRule({commit},data){
        return (new Api()).getList(config.getCustomerDistributionRule,data)
    },
    delCustomerDistributionRule({commit},data){
        return (new Api()).getList(config.delCustomerDistributionRule,data)
    },
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
