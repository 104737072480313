import { config } from '../../js/config'
import { Api } from '../../service/Api'
import  axios  from 'axios'
import tools from '../../utils/tools'

const state = {}

const getters = {}

const actions = {
	addAimActivity({ commit }, data ){
		return new Api().add(config.addAimActivity, data)
	},
    getAimActivityList({ commit }, data){
        return new Api().getList(config.getAimActivity,data)
    },
    getAimActivityDetail({ commit }, data){
        return new Api().detail(config.getAimActivityDetail,data)
    },
    delAimActivity({ commit }, id){
        return new Api().del(config.delAimActivity,id)
    },
	updateAdvStatus({ commit }, data){
		return new Api().add(config.updateAdvStatus,data)
	}
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
