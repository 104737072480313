import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    createDiscount({commit},data){
        return (new Api()).add(config.createDiscount,data)
    },

    discountList({commit},data){
        return (new Api()).getList(config.discountList,data)
    },

    createdVipCard({commit},data){
        return (new Api()).add(config.createdVipCard,data)
    },

    vipCardList({commit},data){
        return (new Api()).getList(config.vipCardList,data)
    },
    setVipCardStatus({commit},data){
        return (new Api()).setStatus('VipCardModel',data.id,data.status)
    },
    delVipCard({commit},data){
        return (new Api()).del(config.delVipCard,data)
    },
    vipCardDetail({commit},data){
        return (new Api()).getList(config.vipCardDetail,data)
    },
    setDiscountStatus({commit},data){
        return (new Api()).setStatus('DiscountModel',data.id,data.status)
    },

    //促销活动
    getPromotionList({commit},data){
        return (new Api()).getList(config.getPromotionList,data)
    },
    //添加
    addPromotion({commit},data){
        return (new Api()).getList(config.addPromotion,data)
    },
    //获取详情
    getPromotionDetail({commit},id){
        return (new Api()).getList(config.getPromotionDetail,{id:id})
    },
    setPromotionStatus1({commit},data){
        return (new Api()).setStatus('PromotionModel',data.id,data.status)
    },
    //获取参加活动的用户列表
    getPromotionUsers({commit},data){
        return (new Api()).getList(config.getPromotionUsers,data)
    },
    addGroupBuying({commit},data){
        return (new Api()).getList(config.addGroupBuying,data)
    },
    getGroupBuyingList({commit},data){
        return (new Api()).getList(config.getGroupBuyingList,data)
    },
    getGroupBuyingDetail({commit},id){
        return (new Api()).getList(config.getGroupBuyingDetail,{id:id})
    },
    setGroupBuyingStatus({commit},data){
        return (new Api()).setStatus('GroupBuyingModel',data.id,data.status)
    },
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
