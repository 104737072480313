import { Api } from "../../service/Api";

const state = () => ({});

const getters = {};

const actions = {
  listCustomer({ commit }, data) {
    return new Api().getList(config.listCustomer, data);
  },
  detailCustomer({ commit }, data) {
    return new Api().getList(config.detailCustomer, data);
  },
  listCustomerConsult({ commit }, data) {
    return new Api().getList(config.listCustomerConsult, data);
  },
  delCustomerConsult({ commit }, data) {
    return new Api().getList(config.delCustomerConsult, data);
  },
  listCustomerRefund({ commit }, data) {
    return new Api().getList(config.listCustomerRefund, data);
  },
  addCustomer({ commit }, data) {
    return new Api().getList(config.addCustomer, data);
  },
  getCustomer({ commit }, data) {
    return new Api().getList(config.getCustomer, data);
  },
  editCustomer({ commit }, data) {
    return new Api().getList(config.editCustomer, data);
  },
  bindUser({ commit }, data) {
    return new Api().getList(config.bindUser, data);
  },
  unbindUser({ commit }, data) {
    return new Api().getList(config.unbindUser, data);
  },
  addCustomerConsult({ commit }, data) {
    return new Api().getList(config.addCustomerConsult, data);
  },
  getCustomerConsult({ commit }, data) {
    return new Api().getList(config.getCustomerConsult, data);
  },
  addCustomerRefund({ commit }, data) {
    return new Api().getList(config.addCustomerRefund, data);
  },
  getCustomerRefund({ commit }, data) {
    return new Api().getList(config.getCustomerRefund, data);
  },
  delCustomerRefund({ commit }, data) {
    return new Api().getList(config.delCustomerRefund, data);
  },
  listCustomerFollowRecord({ commit }, data) {
    return new Api().getList(config.listCustomerFollowRecord, data);
  },
  addCustomerFollowRecord({ commit }, data) {
    return new Api().getList(config.addCustomerFollowRecord, data);
  },
  getCustomerFollowRecord({ commit }, data) {
    return new Api().getList(config.getCustomerFollowRecord, data);
  },
  delCustomerFollowRecord({ commit }, data) {
    return new Api().getList(config.delCustomerFollowRecord, data);
  },
  listCustomerSale({ commit }, data) {
    return new Api().getList(config.listCustomerSale, data);
  },
  changeAttributor({ commit }, data) {
    return new Api().getList(config.changeAttributor, data);
  },
  gainCustomer({ commit }, data) {
    return new Api().getList(config.gainCustomer, data);
  },
  studentProfileList({ commit }, data) {
    return new Api().getList(config.studentProfileList, data);
  },
  selectStudentTags({ commit }, data) {
    return new Api().getList(config.selectStudentTags, data);
  },
  listStudentProfileRefund({ commit }, data) {
    return new Api().getList(config.listStudentProfileRefund, data);
  },
  addStudentProfileRefund({ commit }, data) {
    return new Api().getList(config.addStudentProfileRefund, data);
  },
  getStudentProfileRefund({ commit }, data) {
    return new Api().getList(config.getStudentProfileRefund, data);
  },
  delStudentProfileRefund({ commit }, data) {
    return new Api().getList(config.delStudentProfileRefund, data);
  },
  studentProfileDetail({ commit }, data) {
    return new Api().getList(config.studentProfileDetail, data);
  },
  getStudentProfile({ commit }, data) {
    return new Api().getList(config.getStudentProfile, data);
  },
  studentTagList({ commit }, data) {
    return new Api().getList(config.studentTagList, data);
  },
  editStudentProfile({ commit }, data) {
    return new Api().getList(config.editStudentProfile, data);
  },
  addStudentTag({ commit }, data) {
    return new Api().getList(config.addStudentTag, data);
  },
  delStudentTag({ commit }, data) {
    return new Api().getList(config.delStudentTag, data);
  },
  getStudentTag({ commit }, data) {
    return new Api().getList(config.getStudentTag, data);
  },
  listStudentProfileSale({ commit }, data) {
    return new Api().getList(config.listStudentProfileSale, data);
  },
  userQuestionList({ commit }, data) {
    return new Api().getList(config.userQuestionList, data);
  },
  userCourseTransferRecordList({ commit }, data) {
    return new Api().getList(config.userCourseTransferRecordList, data);
  },
  userWorkList({ commit }, data) {
    return new Api().getList(config.userWorkList, data);
  },
  listStudentProfileState({ commit }, data) {
    return new Api().getList(config.listStudentProfileState, data);
  },
  addStudentProfileState({ commit }, data) {
    return new Api().getList(config.addStudentProfileState, data);
  },
  getStudentProfileState({ commit }, data) {
    return new Api().getList(config.getStudentProfileState, data);
  },
  delStudentProfileState({ commit }, data) {
    return new Api().getList(config.delStudentProfileState, data);
  },
  userCreditLogList({ commit }, data) {
    return new Api().getList(config.userCreditLogList, data);
  },
  userSocialList({ commit }, data) {
    return new Api().getList(config.userSocialList, data);
  },
  userUnionCourseList({ commit }, data) {
    return new Api().getList(config.userUnionCourseList, data);
  },
  closeCourse({ commit }, data) {
    return new Api().getList(config.closeCourse, data);
  },
  getEditUserCheckCourse({ commit }, data) {
    return new Api().getList(config.getEditUserCheckCourse, data);
  },
  editUserCheckCourse({ commit }, data) {
    return new Api().getList(config.editUserCheckCourse, data);
  },
  delUserStudentTag({ commit }, data) {
    return new Api().getList(config.delUserStudentTag, data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
