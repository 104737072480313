import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    getFileDownloadList({commit},data){
        return (new Api()).getList(config.getFileDownloadList,data)
    },
    getFileDownloadDetail({commit},data){
        return (new Api()).getList(config.getFileDownloadDetail,data)
    },
    setFileDownloadStatus({commit},data){
        return (new Api()).setStatus('FileDownloadModel',data.id,data.status)
    },
    delFileDownload({commit},data){
        return (new Api()).del(config.delFileDownload,data)
    },
    addFileDownload({commit},data){
        return (new Api()).add(config.addFileDownload,data)
    },
    editFileDownload({commit},data){
        return (new Api()).edit(config.editFileDownload,data)
    }
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
