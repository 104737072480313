import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    addUser({commit},data){
        return (new Api()).getList(config.addUser,data)
    },
    setTeacherStatus({commit},data){
        return (new Api()).getList(config.setTeacherStatus,data)
    },
    editUser({commit},data){
        return (new Api()).getList(config.editUser,data)
    },
    setCourse({commit},data){
        return (new Api()).getList(config.setCourse,data)
    },
    setCourseClass({commit},data){
        return (new Api()).getList(config.setCourseClass,data)
    },
    getLiveUser({commit},data){
        return (new Api()).getList(config.getLiveUser,data)
    },
    getLiveCourseList({commit},data){
        return (new Api()).getList(config.getLiveCourseList,data)
    },
    getLiveCourseClassList({commit},data){
        return (new Api()).getList(config.getLiveCourseClassList,data)
    },
    delCourseClass({commit},data){
        return (new Api()).getList(config.delCourseClass,data)
    },
    getLiveCourseUser({commit},data){
        return (new Api()).getList(config.getLiveCourseUser,data)
    },
    addLiveCourseUser({commit},data){
        return (new Api()).getList(config.addLiveCourseUser,data)
    },
    delLiveCourseUser({commit},data){
        return (new Api()).getList(config.delLiveCourseUser,data)
    },
    getLoginLinked({commit},data){
        return (new Api()).getList(config.getLoginLinked,data)
    },
    setLiveTrial({commit},data){
        return (new Api()).getList(config.setLiveTrial,data)
    },
    getLiveTrialList({commit},data){
        return (new Api()).getList(config.getLiveTrialList,data)
    },


}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
