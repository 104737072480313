import { Api } from "@/service/Api";

const state = () => ({
  info: {
    id: 1,
  },
});

const getters = {};

const actions = {
  salesDataFileApplyList({ commit }, data = {}) {
    return new Api().getList(config.salesDataFileApplyList, data);
  },
  salesDataFileApplyDel({ commit }, data = {}) {
    return new Api().getList(config.salesDataFileApplyDel, data);
  },
  salesDetail({ commit }, data = {}) {
    return new Api().getList(config.salesDetail, data);
  },
  selectSales({ commit }, data = {}) {
    return new Api().getList(config.selectSales, data);
  },
  salesDataFileApplyAdd({ commit }, data = {}) {
    return new Api().getList(config.salesDataFileApplyAdd, data);
  },
  salesDataFileApplyGet({ commit }, data = {}) {
    return new Api().getList(config.salesDataFileApplyGet, data);
  },
  salesDataFileApplyVerify({ commit }, data = {}) {
    return new Api().getList(config.salesDataFileApplyVerify, data);
  },
  salesDataFileApplyResend({ commit }, data = {}) {
    return new Api().getList(config.salesDataFileApplyResend, data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
};
