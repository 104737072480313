import { config } from '../../js/config'
import { Api } from '../../service/Api'
import  axios  from 'axios'
import tools from '../../utils/tools'

const state = {}

const getters = {}

const actions = {
	addQuestionBank({ commit }, data ){
		return new Api().add(config.addQuestionBank, data)
	},
    getQuestionBankList({ commit }, data){
        return new Api().getList(config.getQuestionBank,data)
    },
    getQuestionBankDetail({ commit }, data){
        return new Api().detail(config.getQuestionBankDetail,data)
    },
    delQuestionBank({ commit }, id){
        return new Api().del(config.delQuestionBank,id)
    },
	exportQuestionBank({ commit }, data){
	        return new Api().post(config.exportQuestionBank,data)
	},
	updateQuestionBankName({commit},data){
		return new Api().post(config.updateQuestionBankName,data)
	},
	updateContent({commit},data){
		return new Api().post(config.updateContent,data)
	},
	getFormatEditData({commit},data){
		return new Api().post(config.getFormatEditData,data)
	},
	selectCreator({commit},data){
		return new Api().post(config.selectCreator,data)
	},
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
