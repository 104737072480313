import { config } from "../../js/config";
import { Api } from "../../service/Api";

const state = {};

const getters = {};

const actions = {
  getClassMessage({ commit }, data) {
    return new Api().getList(config.getClassMessage, data);
  },

  getStudentList({ commit }, data) {
    return new Api().getList(config.getStudentList, data);
  },

  getMomentList({ commit }, data) {
    return new Api().getList(config.getMomentList, data);
  },

  getClassList({ commit }, data) {
    return new Api().getList(config.getClassList, data);
  },

  addClassNotification({ commit }, data) {
    return new Api().add(config.addClassNotification, data);
  },

  deleteClassUser({ commit }, data) {
    return new Api().del(config.deleteClassUser, data);
  },

  deleteClass({ commit }, data) {
    return new Api().delete(data);
  },

  addClass({ commit }, data) {
    return new Api().add(config.addClassRoom, data);
  },
  setClassStatus({ commit }, { model, status, id }) {
    return new Api().setStatus(model, id, status);
  },
  addClassUser({ commit }, data) {
    return new Api().add(config.addClassUser, data);
  },
  setClassRule({ commit }, data) {
    return new Api().add(config.setClassRule, data);
  },
  getClassRule({ commit }, data) {
    return new Api().add(config.getClassRule, data);
  },
  classManageDel({ commit }, data) {
    return new Api().add(config.classManageDel, data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
