import { config } from '../../js/config'
import { Api } from '../../service/Api'
import  axios  from 'axios'
import tools from '../../utils/tools'

const state = {}

const getters = {}

const actions = {
	evaluationList({ commit }, data ){
		return new Api().add(config.evaluationList, data)
	},
    addEvaluation({ commit }, data ){
		return new Api().add(config.addEvaluation, data)
	},
    getEvaluation({ commit }, data ){
		return new Api().add(config.getEvaluation, data)
	},
    delEvaluation({ commit }, data ){
		return new Api().add(config.delEvaluation, data)
	},
    evaluationItemList({ commit }, data ){
		return new Api().add(config.evaluationItemList, data)
	},
    delEvaluationItem({ commit }, data ){
		return new Api().add(config.delEvaluationItem, data)
	},
	addEvaluationItem({ commit }, data ){
		return new Api().add(config.addEvaluationItem, data)
	},
	getEvaluationItem({ commit }, data ){
		return new Api().add(config.getEvaluationItem, data)
	},
	getEvaluationItemOptions({ commit }, data ){
		return new Api().add(config.getEvaluationItemOptions, data)
	},
	saveEvaluationItemOptions({ commit }, data ){
		return new Api().add(config.saveEvaluationItemOptions, data)
	},
	evaluationItemScoreList({ commit }, data ){
		return new Api().add(config.evaluationItemScoreList, data)
	},
	getEvaluationItemScore({ commit }, data ){
		return new Api().add(config.getEvaluationItemScore, data)
	},
	addEvaluationItemScore({ commit }, data ){
		return new Api().add(config.addEvaluationItemScore, data)
	},

	delEvaluationItemScore({ commit }, data ){
		return new Api().add(config.delEvaluationItemScore, data)
	},
	getEvaluationItemSpend({ commit }, data ){
		return new Api().add(config.getEvaluationItemSpend, data)
	},
	saveEvaluationItemSpend({ commit }, data ){
		return new Api().add(config.saveEvaluationItemSpend, data)
	},
	getEvaluationQrcode({ commit }, data ){
		return new Api().add(config.getEvaluationQrcode, data)
	},
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
