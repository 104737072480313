import {Api} from "../../service/Api"
import {config} from "../../js/config"
const state = () => ({

})

const getters = {

}

const actions = {
    getVideoList({commit},data){
        return (new Api()).getList(config.getVideoList,data)
    },
	// 同步视频表
	syncVideo({commit},data = {}){
		return (new Api()).add(config.syncVideo,data)
	}
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
