import { Api } from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
  //分类列表
  getCateList ({ commit }, data) {
    return (new Api()).getList(config.getCateList, data)
  },
  //添加编辑分类
  setCateAdd ({ commit }, data) {
    return (new Api()).getList(config.setCateAdd, data)
  },
  //删除分类
  setCateDel ({ commit }, id) {
    return (new Api()).del(config.setCateDel, id)
  },
  //获取分类
  getCateGget ({ commit }, id) {
    return (new Api()).del(config.getCateGget, id)
  },
  //获取所有分类
  getAllCate ({ commit }) {
    return (new Api()).getList(config.getAllCate)
  },
  //音频书列表
  getAudioBooksList ({ commit }, data) {
    return (new Api()).getList(config.getAudioBooksList, data)
  },
  //编辑添加
  setAudioBooksAdd ({ commit }, data) {
    return (new Api()).getList(config.setAudioBooksAdd, data)
  },
  //获取音频书
  getAudioBooksGet ({ commit }, id) {
    return (new Api()).del(config.getAudioBooksGet, id)
  },
  //删除音频书
  setAudioBooksDel({ commit }, id) {
    return (new Api()).del(config.setAudioBooksDel, id)
  },
  //音频书详情
  getAudioBooksDetail({ commit }, id) {
    return (new Api()).del(config.getAudioBooksDetail, id)
  },
  //章节创建或编辑
  addAudioBooksChapter({ commit }, data) {
    return (new Api()).add(config.addAudioBooksChapter, data)
  },
  //获取章节
  getAudioBooksChapter({ commit }, id) {
    return (new Api()).del(config.getAudioBooksChapter, id)
  },
  //删除章节
  delAudioBooksChapter({ commit }, id) {
    return (new Api()).del(config.delAudioBooksChapter, id)
  },
  //小节列表
  getDetailChapter({ commit }, id) {
    return (new Api()).del(config.getDetailChapter, id)
  },
  //小节获取
  getSection({ commit }, id) {
    return (new Api()).del(config.getSection, id)
  },
  //小节创建
  addAudioBooksSection({ commit }, data) {
    return (new Api()).add(config.addAudioBooksSection, data)
  },
  //小节删除
  delAudioBooksSection({ commit }, id) {
    return (new Api()).del(config.delAudioBooksSection, id)
  },
  //学员列表
  ownUserList({ commit }, data) {
    return (new Api()).getList(config.ownUserList, data)
  },
  //赠书选择列表
  selectAudioBooksBooks({ commit }, data) {
    return (new Api()).getList(config.selectAudioBooksBooks, data)
  },
  //音频书本添加学员
  addOwn({ commit }, data) {
    return (new Api()).getList(config.addOwn, data)
  },
  //删除音频书本学员
  delOwn({ commit }, data) {
    return (new Api()).getList(config.delOwn, data)
  },
  //编辑音频书本学员
  editOwn({ commit }, data) {
    return (new Api()).getList(config.editOwn, data)
  },
  //赠书
  giveBooks({ commit }, data) {
    return (new Api()).getList(config.giveBooks, data)
  },
 
}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
