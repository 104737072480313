import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    getGameList({commit},data){
        return (new Api()).getList(config.getGameList,data)
    },
    setGameAdd({commit},data){
        return (new Api()).getList(config.setGameAdd,data)
    },
    gameGet({commit},id){
        return (new Api()).del(config.gameGet,id)
    },
    gameDel({commit},id){
        return (new Api()).del(config.gameDel,id)
    },
    gameListPass({commit},data){
        return (new Api()).getList(config.gameListPass,data)
    },
    gameAddPass({commit},data){
        return (new Api()).getList(config.gameAddPass,data)
    },
    gameDelPass({commit},id){
        return (new Api()).del(config.gameDelPass,id)
    },
    gameGetPass({commit},id){
        return (new Api()).del(config.gameGetPass,id)
    },
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
