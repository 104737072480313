import { Api } from "../../service/Api";

const state = () => ({});

const getters = {};

const actions = {
  listAppletStartupDiagram({ commit }, data) {
    return new Api().getList(config.listAppletStartupDiagram, data);
  },
  delAppletStartupDiagram({ commit }, data) {
    return new Api().del(config.delAppletStartupDiagram, data);
  },
  addAppletStartupDiagram({ commit }, data) {
    return new Api().getList(config.addAppletStartupDiagram, data);
  },
  getAppletStartupDiagram({ commit }, data) {
    return new Api().del(config.getAppletStartupDiagram, data);
  },
  setAppletStartupDiagramStatus({ commit }, data) {
    return new Api().getList(config.setAppletStartupDiagramStatus, data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
