import axios from 'axios'
import { Message } from 'element-ui'
import { MessageBox } from 'element-ui'
import VueCookies from 'vue-cookies'

axios.interceptors.response.use(
	function (response) {
		// blob
		// if(response.config.responseType === 'blob'){
		//       return response.data
		// }
		//登录token失效
		if (response.data.ret == -1) {
			Message({
				message: response.data.res_info,
				type: 'error'
			})

			if (
				response.data.result == 100005 ||
				response.data.result == 400001 ||
				response.data.result == 400002
			) {
				setTimeout(function () {
					window.location.href = '/login'
				}, 1000)
			}
			return
		}
		//登录token失效
		if (response.data.ret == -3) {
			VueCookies.set('isLogin', false)
			VueCookies.set('token', '')

			MessageBox.confirm('登录失效，请重新登录', '提示', {
				confirmButtonText: '前往登录',
				cancelButtonText: '取消',
				type: 'error'
			})
				.then(() => {
					window.open('/login')
				})
				.catch(() => {})

			// Message({
			//     message: '登录失效，请重新登录',
			//     type: 'error',
			//     duration:6000
			// });
			// setTimeout(function () {
			//     window.location.href = '/login'
			// },1000)
			return
		}

		if (response.data.ret == undefined) {
			Message({
				message: '服务器异常！',
				type: 'error'
			})
			return
		}

		if (response.data.ret != 0) {
			Message({
				message: '服务器繁忙，请稍后再试！',
				type: 'error'
			})
			return
		}

		return response.data
	},
	function (error) {
		console.log(error)
		Message({
			message: '服务器出错了',
			type: 'error'
		})
		return Promise.reject(error)
	}
)

axios.interceptors.request.use(
	function (config) {
		let token = VueCookies.get('token')
		if (config && token) {
			config.headers.common['Authorization'] = 'Bearer ' + token
		}
		return config
	},
	function (error) {
		console.log('请求错误')
		// 对请求错误做些什么
		return Promise.reject(error)
	}
)
