import { config } from "@/js/config";
import { Api } from "@/service/Api";

const state = {};

const getters = {};

const actions = {
  levelAvatarFrameList({ commit }, data) {
    return new Api().add(config.levelAvatarFrameList, data);
  },
  levelAvatarFrameDel({ commit }, data) {
    return new Api().add(config.levelAvatarFrameDel, data);
  },
  levelAvatarFrameAdd({ commit }, data) {
    return new Api().add(config.levelAvatarFrameAdd, data);
  },
  levelAvatarFrameGet({ commit }, data) {
    return new Api().add(config.levelAvatarFrameGet, data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
