import { config } from '../../js/config'
import { Api } from '../../service/Api'
import  axios  from 'axios'
import tools from '../../utils/tools'

const state = {}

const getters = {}

const actions = {
	addAudio({ commit }, data ){
		return new Api().add(config.addAudio, data)
	},
    getAudioList({ commit }, data){
        return new Api().getList(config.getAudio,data)
    },
	editAudio({ commit }, data){
		return new Api().edit(config.editAudio,data)
	},
    delAudio({ commit }, id){
        return new Api().del(config.delAudio,id)
    }
	
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
