import { config } from "../../js/config";
import { Api } from "../../service/Api";
import axios from "axios";
import tools from "../../utils/tools";

const state = {};

const getters = {};

const actions = {
  getAllTeacherGroup({ commit }, data) {
    return new Api().add(config.getAllTeacherGroup, data);
  },
  selectTeacherLeader({ commit }, data) {
    return new Api().add(config.selectTeacherLeader, data);
  },
  selectTeacherGroupParent({ commit }, data) {
    return new Api().add(config.selectTeacherGroupParent, data);
  },
  addTeacherGroup({ commit }, data) {
    return new Api().add(config.addTeacherGroup, data);
  },
  getAllTeacherGroupUsers({ commit }, data) {
    return new Api().add(config.getAllTeacherGroupUsers, data);
  },
  delTeacherGroup({ commit }, data) {
    return new Api().add(config.delTeacherGroup, data);
  },
  addTeacherGroupUsers({ commit }, data) {
    return new Api().add(config.addTeacherGroupUsers, data);
  },
  delTeacherGroupUsers({ commit }, data) {
    return new Api().add(config.delTeacherGroupUsers, data);
  },
  changeClassTeacher({ commit }, data) {
    return new Api().add(config.changeClassTeacher, data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
