import { Api } from "../../service/Api";

const state = () => ({});

const getters = {};

const actions = {
  listDynasty({ commit }, data) {
    return new Api().getList(config.listDynasty, data);
  },
  addDynasty({ commit }, data) {
    return new Api().getList(config.addDynasty, data);
  },
  getDynasty({ commit }, data) {
    return new Api().del(config.getDynasty, data);
  },




  setDynastySort({ commit }, data) {
    return new Api().getList(config.setDynastySort, data);
  },
  delDynasty({ commit }, data) {
    return new Api().del(config.delDynasty, data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
