import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    getFileList({commit},data){
        return (new Api()).getList(config.getFileList,data)
    },
    setFileStatus({commit},data){
        return (new Api()).setStatus('FileModel',data.id,data.status)
    },
    delFile({commit},data){
        return (new Api()).del(config.delFile,{id:data})
    },
    addFile({commit},data){
        return (new Api()).add(config.addFile,data)
    },
    editFile({commit},data){
        return (new Api()).edit(config.editFile,data)
    }
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
