import { Api } from "@/service/Api";
import { config } from "@/js/config";

const state = () => ({});

const getters = {};

const actions = {
  orderList({ commit }, data) {
    return new Api().getList(config.orderList, data);
  },
  getOrder({ commit }, data) {
    return new Api().getList(config.getOrder, data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
