import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './css/app.css'
import store from './store/index'
import VueCookies from 'vue-cookies'
import { config } from './js/config'
import request from './request/request'
import router from './routers/admin.js'
import './interceptors/axios'
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')


window.config = config
window.imageUrl = config.imageUrl
window.request = request

Vue.use(VueCookies)
Vue.use(ElementUI)
Vue.use(VideoPlayer)
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import {BarChart, LineChart} from 'echarts/charts';
import { LegendComponent,ToolboxComponent,DataZoomComponent } from 'echarts/components'
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
	TitleComponent,
	TooltipComponent,
	GridComponent,
	DatasetComponent,
	TransformComponent
} from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';

// 注册必须的组件
echarts.use([
	TitleComponent,
	TooltipComponent,
	GridComponent,
	DatasetComponent,
	TransformComponent,
	BarChart,
	LineChart,
	LegendComponent,
	DataZoomComponent,
	ToolboxComponent,
	LabelLayout,
	UniversalTransition,
	CanvasRenderer
]);

Vue.prototype.$echarts = echarts


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
