import { config } from '../../js/config'
import { Api } from '../../service/Api'

const state = {}

const getters = {}

const actions = {
	listAllPromoterRecord({ commit }, data) {
		return new Api().getList(config.listAllPromoterRecord, data)
	},
	exportAllPromoterRecords({ commit }, data) {
		return new Api().getList(config.exportAllPromoterRecords, data)
	},
	addPromoterRecord({ commit }, data) {
		return new Api().getList(config.addPromoterRecord, data)
	},
}

const mutations = {}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
