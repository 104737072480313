import { config } from "@/js/config";
import { Api } from "@/service/Api";

const actions = {
  giftList({ commit }, data) {
    return new Api().add(config.giftList, data);
  },
  giftAdd({ commit }, data) {
    return new Api().add(config.giftAdd, data);
  },
  getGift({ commit }, id) {
    return new Api().add(config.getGift, { id });
  },
  setGiftStatus({ commit }, data) {
    return new Api().add(config.setGiftStatus, data);
  },
  delGift({ commit }, id) {
    return new Api().add(config.delGift, { id });
  },
  selectGift({ commit }, name) {
    return new Api().add(config.selectGift, { name });
  },
  giving({ commit }, data) {
    return new Api().add(config.giving, data);
  },
};

export default {
  namespaced: true,
  actions,
};
