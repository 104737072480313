import { Api } from "../../service/Api";

const state = () => ({});

const getters = {};

const actions = {
    listType({ commit }, data) {
    return new Api().getList(config.listType, data);
  },
  setTypeSort({ commit }, data) {
    return new Api().getList(config.setTypeSort, data);
  },
  addType({ commit }, data) {
    return new Api().getList(config.addType, data);
  },
  getType({ commit }, data) {
    return new Api().del(config.getType, data);
  },
  delType({ commit }, data) {
    return new Api().del(config.delType, data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
