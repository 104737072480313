import { Api } from "../../service/Api";

const state = () => ({});

const getters = {};

const actions = {
  ListApplyForMoneyRecord({ commit }, data) {
    return new Api().getList(config.ListApplyForMoneyRecord, data);
  },
  selectListPromoter({ commit }, data) {
    return new Api().getList(config.selectListPromoter, data);
  },
  applyAudit({ commit }, data) {
    return new Api().getList(config.applyAudit, data);
  },
  applyPaid({ commit }, id) {
    return new Api().del(config.applyPaid, id);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
