import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    listLevel({commit},data){
        return (new Api()).getList(config.listLevel,data)
    },
    addLevel({commit},data){
        return (new Api()).getList(config.addLevel,data)
    },
    getLevel({commit},data){
        return (new Api()).del(config.getLevel,data)
    },
    delLevel({commit},data){
        return (new Api()).del(config.delLevel,data)
    },
    setLevelStatus({commit},data){
        return (new Api()).getList(config.setLevelStatus,data)
    },

}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
