import { Api } from "../../service/Api";

const state = () => ({});

const getters = {};

const actions = {
  listAuthor({ commit }, data) {
    return new Api().getList(config.listAuthor, data);
  },
  addAuthor({ commit }, data) {
    return new Api().getList(config.addAuthor, data);
  },
  getAuthor({ commit }, data) {
    return new Api().del(config.getAuthor, data);
  },
  setAuthorSort({ commit }, data) {
    return new Api().getList(config.setAuthorSort, data);
  },
  delAuthor({ commit }, data) {
    return new Api().del(config.delAuthor, data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
