import { config } from '../../js/config'
import { Api } from '../../service/Api'
import  axios  from 'axios'
import tools from '../../utils/tools'

const state = {}

const getters = {}

const actions = {
	correctList({ commit }, data ){
		return new Api().add(config.correctList, data)
	},
    verifyCorrect({ commit }, data ){
		return new Api().add(config.verifyCorrect, data)
	},
    detailCorrect({ commit }, data ){
		return new Api().add(config.detailCorrect, data)
	},
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
