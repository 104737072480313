import {Api} from "../../service/Api";
import {config} from "../../js/config";

const state = () => ({

})

const getters = {

}

const actions = {
    getOssInfo({commit},data){
        return (new Api()).getOssInfo(data)
    },
    getBaseData({commit},data){
        return (new Api()).getBaseData(data)
    },
    delete({commit},data){
        return (new Api()).delete(data)
    },
    setSort({ commit }, data) {
        return new Api().edit(config.setCheckSort, data)
    },
    setStatus({ commit }, data) {
        return new Api().edit(config.setStatus, data)
    },
    getVideoUploadAccess({commit},data){
        return (new Api()).getVideoUploadAccess(data)
    },
    makeQrCode({commit},data) {
        return (new Api()).makeQrCode(data)
    },
    importTask({commit},data) {
        return (new Api()).add(config.importTask,data)
    },
	setRecommend({commit},data){
		return (new Api()).edit(config.setRecommend,data)
	},
    vipSelectList({commit}){
		return (new Api()).getList(config.vipSelectList)
	},
    selectWorkByCheckCourse({ commit }, data) {
        return (new Api()).getList(config.selectWorkByCheckCourse, data)
    },
    answerQuestion({ commit }, data) {
        return (new Api()).getList(config.answerQuestion, data)
    },
      //获取诗词基础数据
      getPoemBaseData({commit},data)
      {
          return (new Api()).getList(config.getPoemBaseData,data)
      },
    selectCourse({ commit }, data) {
        return (new Api()).getList(config.selectCourse, data)
    },
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
