import {Api} from "../../service/Api"
import {config} from "../../js/config"
import {Message} from "element-ui";
import { Notification } from 'element-ui';

const state = () => ({
    ws:null,
    timeout:3000,
    msg:null,
    token:''
})

const getters = {
    socketMsg: state =>{
        return state.msg
    },
}

const actions = {
    socketInit({commit},token){
        commit('socketInit',token)
    },
    sendMsgToUser({commit},data){
        let msg = {"userId":data.userId,"toUserId":data.toUserId,"type":"one","msgType":data.msgType,"msg":data.msg}
        commit('sendSocketMessage',JSON.stringify(msg))
    },
}

const mutations = {
    
    socketInit(state,token){
        let _this = this
        let ws = new WebSocket(config.socketUrl+'?token='+token)
        state.token = token
        ws.onopen = (res) => {
            state.ws = ws
            console.log('ws 链接')
        }

        ws.onerror = error => {
            console.log(error)
            console.log('链接错误')
        }
        ws.onclose = res => {
            console.log(res)
            console.log('链接关闭')
        }
        ws.onmessage = msg => {
            let data = JSON.parse(msg.data)
            console.log(data)
      if (data.msg_type == "export") {
       ++this.state.user.info.message_station_nums
       return  Notification.success(data.data)
      }
            switch (data.type) {
                case "error" :
                    Message.error(data.res_info)
                    console.log('错误')
                    break
                case "heart" :
                    console.log('心跳')
                    break
                case "notification" :
                    if(data.msg_type === 'chat'){
                        Notification({
                            title:data.title,
                            message:data.content,
                            onClick:() => {
                                window.location.href = `/classMessage?classId=${data.data.class_id}&chatRoomId=${data.data.chat_room_id}&id=${data.data.id}&user=${data.data.user_name}&userId=${data.data.user_id}`
                            },
                            duration:6000
                        })
                    }else{
                        Notification({
                            title:data.title,
                            message:data.content,
                            duration:0
                        })
                    }
                    break
                default : state.msg = data

            }
            state.msg = data
            console.log('接收消息')
        }
    },
    sendSocketMessage(state,msg){
        state.ws.send(msg)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
