import { config } from "../../js/config";
import { Api } from "../../service/Api";

const state = {};

const getters = {};

const actions = {
  schoolList({ commit }, data) {
    return new Api().add(config.schoolList, data);
  },
  schoolAdd({ commit }, data) {
    return new Api().add(config.schoolAdd, data);
  },
  getSchool({ commit }, id) {
    return new Api().add(config.getSchool, { id });
  },
  delSchool({ commit }, id) {
    return new Api().add(config.delSchool, { id });
  },
  recordList({ commit }, data) {
    return new Api().add(config.recordList, data);
  },
  getWxacode({ commit }, data) {
    return new Api().add(config.getWxacode, data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
