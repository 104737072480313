<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import VueCookies from 'vue-cookies'
export default {
	name: 'App',
	data() {
		return {}
	},
	computed: {
		...mapState(['user'])
	},
	methods: {
		...mapActions('user', ['getUserInfo', 'setUserInfoToState']),
	},
	async mounted() {
		let _this = this
		let isLogin = this.$cookies.get('isLogin')
		if (isLogin == 'true') {
			console.log(isLogin)
			let res = await this.getUserInfo()
			this.role = res.data.role
			this.userId = res.data.id
			this.$cookies.set('userId', this.userId)
			this.$cookies.set('role', this.role)
			this.setUserInfoToState(res.data)
			// this.$store.dispatch('user/getUserInfoToState')
			// console.log(this.user.info)
			this.$store.dispatch('socket/socketInit', res.data.oauth_code)
		}

	}
}
</script>
