import { config } from '../../js/config'
import { Api } from '../../service/Api'
import  axios  from 'axios'
import tools from '../../utils/tools'

const state = {}

const getters = {}

const actions = {
	addTeacherRecord({ commit }, data ){
		return (new Api()).add(config.addTeacherRecord, data)
	},
    getTeacherRecordList({ commit }, data){
        return (new Api()).getList(config.getTeacherRecordList,data)
    },
    getTeacherRecord({commit}, id){
		return (new Api()).getList(config.getTeacherRecord,{id:id})
	},
	verifyTeacherRecord({commit},arr){
		return (new Api()).post(config.verifyTeacherRecord,{ids:arr})
	},
	delTeacherRecord({commit},id){
		return (new Api()).del(config.delTeacherRecord,id)
	},
	exportTeacherRecord({commit},data){
		return (new Api()).post(config.exportTeacherRecord,data)
	}
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
