import {Api} from "../../service/Api"
import {config} from "../../js/config"
const state = () => ({

})

const getters = {

}

const actions = {
    audioBooksVipList({commit},data){
        return (new Api()).getList(config.audioBooksVipList,data)
    },
    audioBooksVipGet({commit},data){
        return (new Api()).getList(config.audioBooksVipGet,data)
    },
    audioBooksVipSet({commit},data){
        return (new Api()).getList(config.audioBooksVipSet,data)
    },
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
