import { config } from "@/js/config";
import { Api } from "@/service/Api";

const actions = {
  ruleList({ commit }, data) {
    return new Api().add(config.ruleList, data);
  },
  addRule({ commit }, data) {
    return new Api().add(config.addRule, data);
  },
  delRule({ commit }, id) {
    return new Api().add(config.delRule, { id });
  },
  getRule({ commit }, id) {
    return new Api().add(config.getRule, { id });
  },
};

export default {
  namespaced: true,
  actions,
};
