import { Api } from "@/service/Api";
import { config } from "@/js/config";

const state = () => ({});

const getters = {};

const actions = {
  attachmentFileList({ commit }, data) {
    return new Api().getList(config.attachmentFileList, data);
  },
  addAttachmentFile({ commit }, data) {
    return new Api().getList(config.addAttachmentFile, data);
  },
  getAttachmentFile({ commit }, data) {
    return new Api().getList(config.getAttachmentFile, data);
  },
  delAttachmentFile({ commit }, data) {
    return new Api().getList(config.delAttachmentFile, data);
  },
  sendRecordList({ commit }, data) {
    return new Api().getList(config.sendRecordList, data);
  },
  sendRecordDetail({ commit }, data) {
    return new Api().getList(config.sendRecordDetail, data);
  },
  emailSend({ commit }, data) {
    return new Api().getList(config.emailSend, data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
