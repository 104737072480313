import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    listMember({commit},data){
        return (new Api()).getList(config.listMember,data)
    },
    addMember({commit},data){
        return (new Api()).getList(config.addMember,data)
    },
    getMember({commit},data){
        return (new Api()).getList(config.getMember,data)
    },
    selectMembers({commit},data){
        return (new Api()).getList(config.selectMembers,data)
    },
    statisticsTop({commit},data){
        return (new Api()).getList(config.statisticsTop,data)
    },
    statisticsData({commit},data){
        return (new Api()).getList(config.statisticsData,data)
    },
    myWxData({commit},data){
        return (new Api()).getList(config.myWxData,data)
    },
    delMember({commit},data){
        return (new Api()).getList(config.delMember,data)
    },
    setMemberStatus({commit},data){
        return (new Api()).getList(config.setMemberStatus,data)
    },
}

const mutations = {

}

export default {
    namespaced: true, 
    state,
    getters,
    actions,
    mutations
}
