
import {Api} from "../../service/Api";

const state = () => ({
    finishLogin:false,
    phone:'',
    password:''
})

const getters = {

}

const actions = {
     async login({commit},data){
        let api = new Api()
        return await api.login(data.phone,data.password)
    },

    wxLogin({commit},data){
        return (new Api()).wxLogin(data)
    }
}

const mutations = {
    setFinishLoginState(state,data){
        state.finishLogin = data
    },
    setPhone(state,data){
        state.phone = data
    },
    setPassword(state,data){
        state.password = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
