import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    getExcerptList({commit},data){
        return (new Api()).getList(config.getExcerptList,data)
    },
    setExcerptStatus({commit},data){
        return (new Api()).setStatus('ExcerptModel',data.id,data.status)
    },
    delExcerpt({commit},data){
        return (new Api()).del(config.delExcerpt,data)
    },
    addExcerpt({commit},data){
        return (new Api()).add(config.addExcerpt,data)
    },
    editExcerpt({commit},data){
        return (new Api()).edit(config.editExcerpt,data)
    },
    pushExcerpt({commit},data){
        console.log(data)
        return (new Api()).push(data.id,data.type)
    },
    getExcerptDetail({commit},data){
        return (new Api()).getList(config.getExcerptDetail,data)
    },
	getBasicData({commit}, data = {}){
		return (new Api()).post(config.getBasicData,data)
	}
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
