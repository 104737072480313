import { Api } from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    getPosterList({ commit }, data) {
        return (new Api()).getList(config.getPosterList, data)
    },
    delTemplate({ commit }, data) {
        return (new Api()).del(config.delTemplate, data)
    },
    addTemplate({ commit }, data) {
        return (new Api()).getList(config.addTemplate, data)
    },
    getTemplate({ commit }, data) {
        return (new Api()).del(config.getTemplate, data)
    },
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
