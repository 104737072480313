import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    getGoodsList({commit},data){
        return (new Api()).getList(config.getGoodsList,data)
    },
    addGoods({commit},data){
        return (new Api()).add(config.addGoods,data)
    },
    getStoreDetail({commit},data){
        return (new Api()).getList(config.getStoreDetail,data)
    },

    getOrderList({commit},data){
        return (new Api()).getList(config.getOrderList,data)
    },
    createdSalesOrder({commit},data){
        return (new Api()).getList(config.createdSalesOrder,data)
    },
    getOrderDetail({commit},data){
        return (new Api()).getList(config.getOrderDetail,data)
    },
    setGoodsStatus({commit},data){
        return (new Api()).setStatus('StoreModel',data.id,data.status)
    },
    getBookStockList({commit},data){
        return (new Api()).getList(config.getBookStockList,data)
    },
    addBookStock({commit},data){
        return (new Api()).add(config.addBookStock,data)
    },
    getBookStockGet({commit},id){
        return (new Api()).getList(config.getBookStockGet,{id:id})
    },
    delBookStock({commit},id){
        return (new Api()).del(config.delBookStock,id)
    },
    changeBookStock({commit},data){
        return (new Api()).getList(config.changeBookStock,data)
    },
    getBookStockRecordList({commit},data){
        return (new Api()).getList(config.getBookStockRecordList,data)
    },
    delBookStockRecord({commit},id){
        return (new Api()).del(config.delBookStockRecord,id)
    },
    delGoods({commit},id){
        return (new Api()).del(config.delGoods,id)
    },
    exportBookStock({commit},data){
        return (new Api()).getList(config.exportBookStock,data)
    },
    exportOrder({commit},data){
        return (new Api()).getList(config.exportOrder,data)
    },
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
