import { Api } from "../../service/Api";

const state = () => ({});

const getters = {};

const actions = {
  listPromoter({ commit }, data) {
    return new Api().getList(config.listPromoter, data);
  },
  selectListAdviser({ commit }, data) {
    return new Api().getList(config.selectListAdviser, data);
  },
  setPromoterStatus({ commit }, data) {
    return new Api().getList(config.setPromoterStatus, data);
  },
  infoCheck({ commit }, data) {
    return new Api().getList(config.infoCheck, data);
  },
  addPromoter({ commit }, data) {
    return new Api().getList(config.addPromoter, data);
  },
  selectListProduct({ commit }, data) {
    return new Api().getList(config.selectListProduct, data);
  },
  getEditProducts({ commit }, data) {
    return new Api().del(config.getEditProducts, data);
  },
  addProducts({ commit }, data) {
    return new Api().getList(config.addProducts, data);
  },
  listPromoterRecord({ commit }, data) {
    return new Api().getList(config.listPromoterRecord, data);
  },
  invalidRecord({ commit }, data) {
    return new Api().getList(config.invalidRecord, data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
