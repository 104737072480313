import { config } from '../../js/config'
import { Api } from '../../service/Api'

const state = {
    classList:[],
    userChatMsgList:{},
    userInfo:{}
}

const getters = {
    getClassUnreadCountNumber:(state)=>(index)=>{
        let n = 0
        state.classList[index].children.forEach(item => {
            n = n + item.unread_count
        })
        return n
    }
}

const actions = {
    initClassChatList({commit}){
        (new Api()).getList(config.getClassChatList,{}).then(res => {
            commit('setChatList',res.data)
        })
    },
    clearUnreadMessage({commit},data){
        (new Api()).getList(config.clearUnreadMessage,data).then(res => {
            commit('setUserChatRoom',data)
        })
    },
    getClassUserInfo({commit},data){
        (new Api()).getList(config.getClassUserInfo,data).then(res => {
            commit('setClassUserInfo',res.data)
        })
    },
    getUserChatRecords({commit},data){
        return (new Api()).getList(config.getUserChatRecords,data)
    },
    sendMessage({commit},data){
        return (new Api()).getList(config.sendMessage,data)
    },
    setUserChatRoom({commit},data){
        commit('setUserChatRoom',data)
    },
    setUserChatMsgList({commit},data){
        commit('setUserChatMsgList',data)
    },
    addUserChatMsg({commit},data){
        commit('addUserChatMsg',data)
    }
}

const mutations = {
    clearUnreadMessage(state,data){
        console.log('clearUnreadMessageclearUnreadMessage')
        console.log(state)
        console.log(data)
    },
    setChatList(state,data){
        state.classList = data
    },
    setUserChatRoom(state,data){
        if(state.classList.length > 0){
            state.classList.forEach((item,index) => {
                if(item.id === data.classId){
                    state.classList[index].children.forEach((c,i)=> {
                        if(c.user_id === data.userId){
                            state.classList[index].children[i].chat_room_id = data.chatRoomId !== undefined ? data.chatRoomId : 0
                            state.classList[index].children[i].unread_count = data.unreadCount !== undefined ? data.unreadCount : 0
                        }
                    })
                }
            })
        }
    },
    setUserChatMsgList(state,data){
        state.userChatMsgList[data.key] = data.data
    },
    addUserChatMsg(state,data){
        let a = []
        let b =  state.userChatMsgList[data.key]
        state.userChatMsgList[data.key] = []
        state.userChatMsgList[data.key] = b.concat(data.data)
        console.log(state.userChatMsgList[data.key])
    },
    setClassUserInfo(state,data){
        state.userInfo = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
