import { config } from '../../js/config'
import { Api } from '../../service/Api'
import  axios  from 'axios'
import tools from '../../utils/tools'

const state = {}

const getters = {}

const actions = {
	addKnowledge({ commit }, data ){
		return new Api().add(config.addKnowledge, data)
	},
    getKnowledgeList({ commit }, data){
        return new Api().getList(config.getKnowledgeList,data)
    },
    getKnowledge({ commit }, data){
        return new Api().detail(config.getKnowledge,data)
    },
    delKnowledge({ commit }, id){
        return new Api().del(config.delKnowledge,id)
    }
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
